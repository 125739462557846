import {PropsWithChildren, useCallback} from 'react';
import {useTranslation} from 'react-i18next';

import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, {Dayjs} from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import Utc from 'dayjs/plugin/utc';

import {useAuthentication} from '../authentication';

import('dayjs/locale/it');
import('dayjs/locale/en');
import('dayjs/locale/es');

dayjs.extend(localeData);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(Utc);

export const useLocale = () => {
  const {i18n} = useTranslation();

  const getLocale = useCallback((country?: string) => {
    return !country ? 'en' : country.toLowerCase();
  }, []);

  const setLocale = useCallback(
    (country: string) => {
      const formattedCountry = getLocale(country);
      dayjs.locale(formattedCountry);

      i18n.changeLanguage(formattedCountry);
    },
    [getLocale, i18n],
  );

  const formatDate = useCallback((date: string | Dayjs) => {
    const dayObj: Dayjs = dayjs(date).locale(dayjs.locale());
    return `${dayObj.utc().format('ll')}`;
  }, []);

  return {getLocale, setLocale, formatDate};
};

export const LocalizationDayjs = ({children}: PropsWithChildren) => {
  const {userData} = useAuthentication();
  const {getLocale} = useLocale();
  const {t} = useTranslation();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={getLocale(userData?.language)}
      localeText={{
        clearButtonLabel: t('Calendar.ctas.clear'),
        okButtonLabel: t('Calendar.ctas.ok'),
        cancelButtonLabel: t('Calendar.ctas.cancel'),
      }}
      children={children}
    />
  );
};
