import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';

import {Box, Button} from '@mui/material';
import {
  GridRowId,
  GridRowParams,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import {useMutation, useQuery} from '@tanstack/react-query';

import {IconSVG} from '@/atoms/IconSVG';
import {Table} from '@/atoms/Table';
import {
  ExtendedColDef,
  ShowCheckbox,
  TaskListColumnsVisibility,
  TaskListSortingKeys,
  useTableColumns,
} from '@/atoms/Table/TableColumns';
import {Text} from '@/atoms/Typography/Text';
import {useToast} from '@/hooks/toast';
import {useNavigation} from '@/navigation/useNavigation';
import {MembersModal} from '@/organisms/MembersModal';
import {ORDER_BY} from '@/organisms/TaskListFilters/interfaces.d';
import {useStyles} from '@/pages/Clienteling/SubPages/TaskListPage/TaskListTable/styles';
import {useCampaignsContext} from '@/store/CampaignsContext';
import {useAuthentication} from '@/store/authentication';
import {TASK_STATUS, useCampaigns} from '@/store/campaigns';
import {FEEDBACK_STATUS_VOICES} from '@/store/feedback';
import {Member} from '@/store/members/types';
import {useTasks} from '@/store/tasks';

const TaskListTable = () => {
  const {t} = useTranslation();
  const {selectedItemsToast} = useStyles();

  const {getTasks} = useCampaigns();
  const {assignTask, assignTaskToMe} = useTasks();

  const {showSuccessToastAssignTask, showErrorToast} = useToast();
  const {goToTask} = useNavigation();
  const {userData, isStoreAssociate} = useAuthentication();
  const {taskColumns} = useTableColumns();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedTasks, setSelectedTasks] = useState<GridRowId[]>([]);
  const [assigneeId, setAssigneeId] = useState<string>('');

  const {
    data: {code, assignees, visibility, status, page, sortBy, orderBy},
    api: {changePage, handleOrderChange, refetchFilters},
  } = useCampaignsContext();

  const handleColumnHeaderClick = useCallback(
    (params: any) => {
      const {colDef} = params;
      const {sortable, sortByKey} = colDef as ExtendedColDef;

      if (sortable) {
        handleOrderChange(
          sortByKey,
          orderBy === ORDER_BY.ASC ? ORDER_BY.DESC : ORDER_BY.ASC,
        );
      }
    },
    [handleOrderChange, orderBy],
  );

  const {
    data: Tasks,
    isFetching: TasksLoading,
    refetch,
  } = useQuery(
    [
      'tasks',
      visibility,
      status,
      sortBy,
      orderBy,
      code,
      assignees,
      page,
      userData?.currentStore.id,
    ],
    () =>
      getTasks({
        storeId: userData?.currentStore.id!,
        status,
        visibility,
        sortBy,
        orderBy,
        ...(code && {codes: code.split(',')}),
        ...(assignees && {assignee: assignees.split(',')}),
        page: Number(page),
        limit: 30,
      }),
    {
      retry: false,
    },
  );

  const handleChangePage = useCallback(
    (event: ChangeEvent<unknown>, newPage: number) => {
      changePage(newPage);
    },
    [changePage],
  );

  const showToast = useCallback(
    (count: number) => {
      showSuccessToastAssignTask(
        t('SingleTaskDetails.postFeedback.toast.successAssign', {count}),
      );
    },
    [showSuccessToastAssignTask, t],
  );

  const normalizeId = useCallback(
    (id: string | any) => {
      return id.replace(`${visibility}`, '').replace(`${status}`, '');
    },
    [status, visibility],
  );

  const normalizedSelectedTasks = useMemo(() => {
    return selectedTasks?.map((id: any) => normalizeId(id));
  }, [normalizeId, selectedTasks]);

  const {mutate: assignTaskMutate} = useMutation(
    () =>
      assignTask(
        userData?.currentStore.id!,
        normalizedSelectedTasks,
        assigneeId,
      ),
    {
      onSuccess: () => {
        setSelectedTasks([]);
        refetch();
        refetchFilters();
        showToast(normalizedSelectedTasks.length);
      },
      onError: () => {
        showErrorToast(t('SingleTaskDetails.postFeedback.toast.error'));
      },
    },
  );

  const {mutate: assignToMeMutate} = useMutation(
    () => assignTaskToMe(userData?.currentStore.id!, normalizedSelectedTasks),
    {
      onSuccess: () => {
        setSelectedTasks([]);
        refetch();
        refetchFilters();
        showToast(normalizedSelectedTasks.length);
      },
      onError: () => {
        showErrorToast(t('SingleTaskDetails.postFeedback.toast.error'));
      },
    },
  );

  /* Toggle member modal view */
  const toggleMembersModal = useCallback(() => {
    setOpenModal(prevValue => !prevValue);
  }, []);

  const saveNewAssignee = useCallback(
    (member: Member) => {
      setAssigneeId(member.id);
      toggleMembersModal();
      assignTaskMutate();
    },
    [assignTaskMutate, toggleMembersModal],
  );

  const saveTaskToMe = useCallback(() => {
    assignToMeMutate();
  }, [assignToMeMutate]);

  const taskRows = useMemo(
    () =>
      Tasks?.data?.map(item => {
        return {
          id: `${item.id}${visibility}${status}`,
          CUSTOMER: `${item.firstName} ${item.lastName}`,
          MOBILE: item.mobile,
          ADDRESS_CITY: item.addressCity,
          SEGMENT: item.segment,
          LAST_VISIT_DATE: item.lastVisitDate,
          CURRENT_ASSIGNEE: item.assignee
            ? `${item.assignee.firstName} ${item.assignee.lastName}`
            : undefined,
          CAMPAIGN: {
            campaignName: item.campaignName,
            campaignColor: item.campaignColor,
          },
          startDate: item.campaignStartDate,
          endDate: item.campaignEndDate,
          CALL_TIME: item.expireDate,
          FEEDBACK_STATUS: item.feedbackStatus,
        };
      }) || [],
    [Tasks?.data, status, visibility],
  );

  const onRowClick = useCallback(
    (e: GridRowParams<any>) => {
      goToTask(normalizeId(e.id));
    },
    [goToTask, normalizeId],
  );

  const showCheckBox = useMemo(() => {
    return ShowCheckbox.get(visibility!)
      ?.get(status!)
      ?.get(userData?.roles[0].name!);
  }, [status, userData?.roles, visibility]);

  const checkRowSelectable = useCallback(
    (params: GridRowParams<any>): boolean => {
      const {FEEDBACK_STATUS, CURRENT_ASSIGNEE} = params?.row;

      const isTaskDisabled =
        FEEDBACK_STATUS === FEEDBACK_STATUS_VOICES.CONTACTED ||
        FEEDBACK_STATUS === FEEDBACK_STATUS_VOICES.ALREADY_PURCHASED ||
        FEEDBACK_STATUS === FEEDBACK_STATUS_VOICES.NOT_CONTACTABLE ||
        FEEDBACK_STATUS === FEEDBACK_STATUS_VOICES.MARKETING_OPTOUT;

      if (isTaskDisabled) return false;

      const isAssigned = Boolean(CURRENT_ASSIGNEE);

      return !(isStoreAssociate && isAssigned);
    },
    [isStoreAssociate],
  );

  // Reset table when tab changes
  useEffect(() => {
    setSelectedTasks([]);
  }, [code, assignees, visibility, status, page, sortBy, orderBy]);

  const toastLabel = useMemo(() => {
    if (status === TASK_STATUS.ASSIGNED)
      return t('ContextMenu.subMenuItems.EditAssignee');

    return isStoreAssociate
      ? t('ContextMenu.subMenuItems.AssignToMe')
      : t('ContextMenu.subMenuItems.AssignTask');
  }, [isStoreAssociate, status, t]);

  return (
    <>
      <Table
        columnHeaderHeight={62}
        sx={{
          '.MuiDataGrid-row': {
            cursor: 'pointer',
            opacity: 1,
            transition: 'all 0.2s linear',
            '&:hover': {
              opacity: 0.7,
            },
          },
        }}
        sortModel={[
          {
            field: sortBy ? TaskListSortingKeys.get(sortBy)! : '',
            sort: orderBy?.toLocaleLowerCase() as any,
          },
        ]}
        columns={taskColumns}
        rows={taskRows}
        currentPage={Number(page)}
        totalPages={Tasks?.metadataDTO ? Tasks?.metadataDTO?.total_pages : 1}
        setPage={handleChangePage}
        keepNonExistentRowsSelected={false}
        onRowSelectionModelChange={(e: GridRowSelectionModel) =>
          setSelectedTasks(e)
        }
        rowSelectionModel={selectedTasks}
        onRowClick={e => onRowClick(e)}
        onColumnHeaderClick={handleColumnHeaderClick}
        columnVisibilityModel={TaskListColumnsVisibility.get(visibility!)?.get(
          status!,
        )}
        isRowSelectable={checkRowSelectable}
        checkbox={showCheckBox}
        loading={!Tasks || TasksLoading}
      />
      {openModal && (
        <MembersModal
          userLuxId={userData?.luxId!}
          storeId={userData?.currentStore.id!}
          banner={userData?.banner!}
          country={userData?.country!}
          onClose={toggleMembersModal}
          onSave={member => saveNewAssignee(member)}
          includeMe={!isStoreAssociate}
        />
      )}
      {selectedTasks?.length !== 0 && showCheckBox && (
        <Box sx={selectedItemsToast}>
          <Text variant="headline5" heavy>
            {t('CustomerProfile.tables.toast.selectedItems', {
              count: selectedTasks?.length,
            })}
          </Text>
          <Box>
            <Button
              variant="outlined"
              onClick={isStoreAssociate ? saveTaskToMe : toggleMembersModal}
              endIcon={<IconSVG icon="assign_to_me" size={16} />}>
              {toastLabel}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default React.memo(TaskListTable);
