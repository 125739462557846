/**
 * Interfaces for feedback module
 * @version 2
 */
import {Nullable} from '@/lib/utils';

export enum FEEDBACK_STATUS_TYPES {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum FEEDBACK_STATUS {
  NEW = 'NEW',
  CONTACTED_POSITIVE = 'CONTACTED_POSITIVE',
  CONTACTED_NEGATIVE = 'CONTACTED_NEGATIVE',
  NOT_CONTACTABLE = 'NOT_CONTACTABLE',
  WAITING = 'WAITING',
  NO_ANSWER = 'NO_ANSWER',
  ALREADY_PURCHASED = 'ALREADY_PURCHASED',
  MARKETING_OPTOUT = 'MARKETING_OPTOUT',
}

export enum REFUSAL_REASONS {
  DNC = 'DNC',
  ABE = 'ABE',
  NIA = 'NIA',
  BAD_EXPERIENCE = 'BAD_EXPERIENCE',
  NRG = 'NRG',
}

export enum APPOINTMENT_SETTINGS {
  READ = 'READ',
  READ_AND_WRITE = 'READ_AND_WRITE',
}

export enum FEEDBACK_FIELD_CODES {
  STATUS = 'STATUS',
  REFUSAL_REASON = 'REFUSAL_REASON',
  APPOINTMENT_SETTING = 'APPOINTMENT_SETTING',
  NOTE = 'NOTE',
  C_DATE_TIME = 'C_DATE_TIME',
}

/**
 * @brief Get feedback configuration params
 */

export interface FieldAcceptedValue<K, V> {
  [key: K | string]: V;
}

interface BaseFeedbackConfiguration {
  campaignCategory?: string;
  fieldName?: string;
  fieldType?: string;
  fieldOrder?: number;
  feedbackTaskConfigId?: number;
}

export type FeedbackStatusConfiguration = {
  fieldCode?: FEEDBACK_FIELD_CODES.STATUS;
  fieldAcceptedValue?: FieldAcceptedValue<
    FEEDBACK_STATUS_TYPES,
    Array<FieldAcceptedValue<string, FEEDBACK_STATUS>>
  >;
};

export type FeedbackRefusalReasonConfiguration = {
  fieldCode?: FEEDBACK_FIELD_CODES.REFUSAL_REASON;
  fieldAcceptedValue?: FieldAcceptedValue<string, REFUSAL_REASONS>;
};

export type FeedbackAppointmentSettingConfiguration = {
  fieldCode?: FEEDBACK_FIELD_CODES.APPOINTMENT_SETTING;
  fieldAcceptedValue?: {
    TAB_LITE: APPOINTMENT_SETTINGS;
  };
};

export type FeedbackBasicConfiguration = {
  fieldCode?: FEEDBACK_FIELD_CODES.NOTE | FEEDBACK_FIELD_CODES.C_DATE_TIME;
};

export type FeedbackConfiguration<T> = BaseFeedbackConfiguration & T;

export type FeedbackConfigurations = FeedbackConfiguration<
  | FeedbackStatusConfiguration
  | FeedbackRefusalReasonConfiguration
  | FeedbackAppointmentSettingConfiguration
  | FeedbackBasicConfiguration
>;

export interface GetFeedbackConfigurationParamsV2 {
  taskCategory: string;
}

export type GetFeedbackConfigurationResponseV2 =
  | Array<FeedbackConfigurations>
  | undefined;

/**
 * @brief Get feedback params
 */

export interface GetFeedbackParamsV2 {
  storeId: string;
  taskId: string;
}

export interface GetFeedbackResponseV2 {
  note: Nullable<string>;
  status: Nullable<FEEDBACK_STATUS>;
  refusalReason: Nullable<REFUSAL_REASONS>;
  callBackDateTime: Nullable<string>;
  updatedNote: Nullable<string>;
}

/**
 * @brief Post user feedback params
 */

export interface PostFeedbackParamsV2 {
  storeId: string;
  taskId: string;
  callBackDateTime?: string;
  status: FEEDBACK_STATUS;
  note?: string;
  refusalReason?: REFUSAL_REASONS;
  assigneeId?: string;
}
