import React, {useMemo, useState, useCallback} from 'react';
import {BsThreeDots} from 'react-icons/bs';
import {PiSealCheckLight} from 'react-icons/pi';

import {
  Skeleton,
  Stack,
  Box,
  Divider,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from '@mui/material';
import dayjs from 'dayjs';

import {BodyText} from '@/atoms/BodyText';
import {Card} from '@/atoms/Card';
import {Headline5} from '@/atoms/Headline5';
import {HeadlineText} from '@/atoms/HeadlineText';
import {IconSVG} from '@/atoms/IconSVG';
import {IconNames} from '@/atoms/IconSVG/interfaces';
import {LabelValue} from '@/atoms/LabelValue';
import {Link} from '@/atoms/Link';
import {PurchaseCategory} from '@/atoms/PurchaseCategory';
import {SuspenseBackgroundImage} from '@/atoms/SuspenseBackgroundImage';
import {useBaseTranslation} from '@/hooks/useBaseTranslation';
import {useCurrencyFormat} from '@/hooks/useCurrencyFormat';
import {CarouselModal} from '@/molecules/CarouselModal';
import {PurchasesDetailsProps} from '@/organisms/PurchasesDetails/interfaces.d';
import {useStyles} from '@/organisms/PurchasesDetails/styles';
import {
  TransactionDataProps,
  SpecifyProductKindProps,
} from '@/organisms/TransactionCard/interfaces';
import {Colors} from '@/themes/variables';

const BASE_TRANSLATION = 'PurchaseDetails';

const PurchasesDetails = ({
  response,
  viewPurchaseHistoryCB,
  country,
}: PurchasesDetailsProps) => {
  const {getTranslationWithValue} = useBaseTranslation(BASE_TRANSLATION);
  const styles = useStyles();

  const printHeader = useMemo(() => {
    if (!response) {
      return <Skeleton height="25px" animation="wave" variant="rounded" />;
    }

    return (
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <HeadlineText superHeavy>
          {getTranslationWithValue(0, 'title')}
        </HeadlineText>
        <Link
          position="center"
          linkVariant="dark"
          textVariant="body"
          href="#"
          handleOnClick={viewPurchaseHistoryCB}>
          {getTranslationWithValue(0, 'viewPurchaseHistory')}
        </Link>
      </Stack>
    );
  }, [getTranslationWithValue, response, viewPurchaseHistoryCB]);

  const {formatToCurrency} = useCurrencyFormat();

  const printInfo = useMemo(() => {
    if (!response) {
      return <Skeleton height="50px" animation="wave" variant="rounded" />;
    }

    const {purchaseInformation} = response;

    return (
      <Stack sx={styles.infoContainer}>
        <LabelValue
          label={getTranslationWithValue(0, 'infos.customerSegment')}
          value={
            purchaseInformation?.customerSegment
              ? getTranslationWithValue(
                  0,
                  `segmentMapping.${purchaseInformation?.customerSegment}.label`,
                  {},
                  purchaseInformation?.customerSegment,
                )
              : '-'
          }
        />
        {/* Commented due to CR https://luxotticaretail.atlassian.net/browse/CRMAPP-429  */}
        {/* <LabelValue */}
        {/*   label={getTranslationWithValue(0, 'infos.lifeTimeValue')} */}
        {/*   value={ */}
        {/*     !!purchaseInformation?.lifeTimeValue */}
        {/*       ? formatToCurrency(+purchaseInformation?.lifeTimeValue) */}
        {/*       : '-' */}
        {/*   } */}
        {/* /> */}
        <LabelValue
          label={getTranslationWithValue(0, 'infos.purchaseCategory')}
          value={
            purchaseInformation?.purchaseCategory?.length > 0 ? (
              <PurchaseCategory
                categories={purchaseInformation?.purchaseCategory}
              />
            ) : (
              '-'
            )
          }
        />
      </Stack>
    );
  }, [
    formatToCurrency,
    getTranslationWithValue,
    response,
    styles.infoContainer,
  ]);

  const printLastPurchases = useMemo(() => {
    if (!response) {
      return <Skeleton height="250px" animation="wave" variant="rounded" />;
    }

    const {transactions} = response;

    if (transactions?.length === 0) {
      return (
        <Box textAlign="center">
          <BodyText>{getTranslationWithValue(0, 'emptyState')}</BodyText>
        </Box>
      );
    }

    return (
      <Stack direction="column" gap={1.6}>
        <Headline5 superHeavy>
          {getTranslationWithValue(0, 'lastPurchases')}
        </Headline5>
        {transactions?.map((transaction, i) => {
          return (
            <TransactionBox
              country={country}
              key={`${transaction?.transactionId}-${i}`}
              {...transaction}
            />
          );
        })}
      </Stack>
    );
  }, [country, getTranslationWithValue, response]);

  return (
    <Card>
      <Stack direction="column" gap={2.4}>
        {printHeader}
        {printInfo}
        {printLastPurchases}
      </Stack>
    </Card>
  );
};

export default React.memo(PurchasesDetails);

interface TransactionBoxProps extends TransactionDataProps {
  country: string;
}

const TransactionBox = React.memo(
  ({country, ...transaction}: TransactionBoxProps) => {
    const theme = useTheme();
    const {getTranslationWithValue} = useBaseTranslation(
      BASE_TRANSLATION,
      'ProductCard',
    );
    const {formatToCurrency} = useCurrencyFormat();
    const isTabletPortrait = useMediaQuery(theme.breakpoints.down('md'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));
    const styles = useStyles();
    const [showModal, setShowModal] = useState(false);
    const [initialIndex, setInitialIndex] = useState<number | null>(null);

    const productsWithImage = useMemo(() => {
      return transaction?.products?.filter(product => !!product?.image);
    }, [transaction?.products]);

    const productsWithoutImage = useMemo(() => {
      return transaction?.products?.filter(product => !product?.image);
    }, [transaction?.products]);

    const toggleGallery = useCallback(
      (open: boolean, initialIndex: number | null) => {
        setInitialIndex(initialIndex);
        setShowModal(open);
      },
      [],
    );

    const productIcon = useCallback(
      (productKind: SpecifyProductKindProps): JSX.Element => {
        let icon: IconNames;

        switch (productKind) {
          case 'SUN':
            icon = 'lens_sunglasses';
            break;
          case 'LENSES':
            icon = 'lens_glasses';
            break;
          case 'FRAMES':
            icon = 'glasses';
            break;
          case 'CONTACTS':
            icon = 'lens_contact';
            break;
          case 'APPAREL':
            icon = 'clothes';
            break;
          case 'READERS':
            icon = 'readers';
            break;
          case 'WARRANTY':
            return <PiSealCheckLight color={Colors.GreyText} fontSize={32} />;
          case 'ACCESSORIES':
            icon = 'retail';
            break;
          default:
            icon = 'no_kind';
            break;
        }

        return <IconSVG icon={icon} color={Colors.GreyText} size={28} />;
      },
      [],
    );

    return (
      <Stack direction="column" sx={styles.transactionBoxContainer}>
        <Stack
          display="grid"
          gridTemplateColumns="1fr max-content"
          alignItems={isTabletPortrait || isDesktop ? 'center' : 'start'}
          gap={0.4}>
          <LabelValue
            direction={isTabletPortrait || isDesktop ? 'row' : 'column'}
            label={
              transaction?.transactionDate !== null
                ? dayjs(transaction?.transactionDate)
                    .utc()
                    .format(
                      dayjs().locale() === 'it' ? 'D MMM YYYY' : 'Do MMMM YYYY',
                    )
                : '-'
            }
            value={
              <BodyText superHeavy>
                {getTranslationWithValue(0, 'orderNumber', {
                  orderId: transaction?.orderId,
                })}
              </BodyText>
            }
          />
          <BodyText
            className="transactionStatus"
            superHeavy
            textAlign="right"
            width="max-content">
            {getTranslationWithValue(
              1,
              `orderStatus.${transaction?.orderStatus}`,
            )}
          </BodyText>
        </Stack>
        <Stack
          direction="column"
          gap={1.6}
          divider={<Divider orientation="horizontal" flexItem />}>
          <Stack display="grid" gridTemplateColumns="repeat(2, 1fr)">
            <LabelValue
              label={getTranslationWithValue(0, 'transactionId')}
              value={transaction?.transactionId ?? '-'}
            />
            <LabelValue
              label={getTranslationWithValue(0, 'transactionAmount')}
              value={
                !!transaction?.totalTenderValue
                  ? formatToCurrency(transaction?.totalTenderValue, country)
                  : '-'
              }
            />
          </Stack>
          {transaction?.products?.length > 0 && (
            <Stack direction="row" gap={1}>
              {productsWithImage?.slice(0, 3).map((product, index) => {
                return (
                  <React.Suspense
                    key={`${transaction?.transactionId}_${product?.productId}_${index}`}
                    fallback={
                      <Box sx={styles.productImage(true)}>
                        <CircularProgress
                          disableShrink
                          color="primary"
                          size={25}
                        />
                      </Box>
                    }>
                    <Box onClick={() => toggleGallery(true, index)}>
                      <SuspenseBackgroundImage
                        sx={styles.productImage(true)}
                        src={product?.image!}>
                        <Box className="hoverBox" sx={styles.productImageCover}>
                          <BsThreeDots size={22} color={Colors.White} />
                        </Box>
                      </SuspenseBackgroundImage>
                    </Box>
                  </React.Suspense>
                );
              })}
              {productsWithImage?.length < 3 && (
                <>
                  {productsWithoutImage
                    ?.slice(0, 3 - productsWithImage?.length)
                    .map((product, index) => {
                      return (
                        <Box
                          key={`${transaction?.transactionId}_${product?.productId}_${index}`}
                          sx={styles.productImage(false)}>
                          {productIcon(product?.specifyProductKind!)}
                        </Box>
                      );
                    })}
                </>
              )}
            </Stack>
          )}
        </Stack>
        {showModal && (
          <CarouselModal
            open={showModal}
            onClose={() => toggleGallery(false, null)}
            images={productsWithImage?.map(p => p?.image!)}
            {...(initialIndex !== null && {initialIndex})}
            dialogTitle={getTranslationWithValue(0, 'orderNumber', {
              orderId: transaction?.orderId,
            })}
            carouselTitle={getTranslationWithValue(0, 'productGallery')}
          />
        )}
      </Stack>
    );
  },
);

TransactionBox.displayName = 'TransactionBox';
