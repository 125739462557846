import {LuxStore} from '@/store/stores';

import {AuthenticatedUserStatus, Role} from './users';

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  SUSPENDED = 'SUSPENDED',
  UNSUBSCRIBED = 'UNSUBSCRIBED',
  MIGRATED = 'MIGRATED',
}

export enum Gender {
  MALE = 'M',
  FEMALE = 'F',
  OTHER = 'OTHER',
}

export interface User {
  cardNumber: string;
  cardNumberUrl: string;
  firstName: string;
  lastName: string;
  email: string;
  brandId: number;
  banner: string;
  country: string;
  userStatus: UserStatus;
}

export interface ExtendedUser extends User {
  birthDate: string;
  language: string;
  currency: string;
  gender: Gender;
  tierId: number;
  activationDate: string;
  brand: string;
  day_birth?: string;
  year_birth?: string;
}

export interface ReducedUser
  extends Pick<
    ExtendedUser,
    'firstName' | 'lastName' | 'birthDate' | 'gender'
  > {}

export interface AuthenticatedUser extends Pick<LuxStore, 'configuration'> {
  luxId: string;
  banner: string;
  country: string;
  language: string;
  roles: Role[];
  currentStore: Omit<LuxStore, 'configuration'>;
  firstName: string;
  lastName: string;
  status: AuthenticatedUserStatus;
  email: string;
  token: string;
  namToken: string;
  hasLoyalty: boolean;
}
