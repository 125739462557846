import React, {useCallback, useMemo, useEffect} from 'react';
import ReactGA from 'react-ga4';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';

import {Skeleton, Stack, Container} from '@mui/material';
import {GridColumnHeaderParams} from '@mui/x-data-grid';
import {useMutation, useQuery} from '@tanstack/react-query';
import dayjs, {Dayjs} from 'dayjs';
import {Dispatch} from 'redux';

import {ChipDatepicker} from '@/atoms/ChipDatepicker';
import {ChipDropdown} from '@/atoms/ChipDropdown';
import {IconSVG} from '@/atoms/IconSVG';
import {Link} from '@/atoms/Link';
import {LoadingPage} from '@/atoms/LoadingPage';
import {Table} from '@/atoms/Table';
import {useTableColumns} from '@/atoms/Table/TableColumns';
import {useToast} from '@/hooks/toast';
import {HeadingCard} from '@/molecules/HeadingCard';
import {AuthRoutes} from '@/navigation/routes';
import {useNavigation} from '@/navigation/useNavigation';
import {useStyle} from '@/pages/Clienteling/AreaManagerHome/style';
import {useAuthentication} from '@/store/authentication';
import {MembersReportFiltersAction} from '@/store/membersReportFilters/definitions';
import {MembersReportFiltersReset} from '@/store/membersReportFilters/membersReportFilters.actions';
import {ReportDetailsFiltersAction} from '@/store/reportDetailsFilters/definitions';
import {ReportDetailsFiltersReset} from '@/store/reportDetailsFilters/reportDetailsFilters.actions';
import {useReportFilters} from '@/store/reportFilters/hooks';
import {
  ReportFilters,
  ReportFiltersAction,
} from '@/store/reportFilters/interfaces';
import {
  ReportFiltersChangeField,
  ReportFiltersChangeDates,
  ReportFiltersChangePage,
  ReportFiltersReset,
} from '@/store/reportFilters/reportFilters.actions';
import {
  reportFiltersInitialStartDate,
  reportFiltersInitialEndDate,
} from '@/store/reportFilters/reportFilters.reducer';
import {selectReportFilters} from '@/store/reportFilters/selectors';
import {useReport} from '@/store/reports/hooks';
import {useStores} from '@/store/stores';
import {Base} from '@/templates/Base';
import theme from '@/themes/theme';

const AreaManagerHome = () => {
  const {t} = useTranslation();
  const {showSuccessStoreChangeToast, showErrorToast} = useToast();
  const {patchStoreByStoreId} = useStores();
  const {reportsColumns} = useTableColumns();
  const {goToPath} = useNavigation();
  const {getAuth, userStoreId} = useAuthentication();

  const styles = useStyle();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: `${window.location.pathname}${window.location.search}`,
      title: 'Area manager home',
    });
  }, []);

  // Filters state
  const reportFilters = useSelector<
    {reportFilters: ReportFilters},
    ReportFilters
  >(selectReportFilters);

  console.log('reportFilters', reportFilters);

  const dispatch =
    useDispatch<
      Dispatch<
        | ReportFiltersAction
        | ReportDetailsFiltersAction
        | MembersReportFiltersAction
      >
    >();

  const hasFilters = useMemo(() => {
    const {regions, stores, codes, status, startDate, endDate} = reportFilters;
    const hasDiffStartDate = !reportFiltersInitialStartDate
      .utc()
      .isSame(startDate, 'date');
    const hasDiffEndDate = !reportFiltersInitialEndDate
      .utc()
      .isSame(endDate, 'date');
    const hasRegions = regions?.length > 0;
    const hasStores = stores?.length > 0;
    const hasCodes = codes?.length > 0;
    const hasStatuses = status?.length > 0;

    return (
      hasRegions ||
      hasStores ||
      hasCodes ||
      hasStatuses ||
      hasDiffStartDate ||
      hasDiffEndDate
    );
  }, [reportFilters]);

  // Filters API
  const {getFilterAreas, getFilterStores, getFilterCampaigns, getFilterStatus} =
    useReportFilters();

  const {getReportsHeader, getReportsCampaigns} = useReport();

  // Areas filter
  const {data: AreasFilter, isFetching: AreasFilterLoading} = useQuery(
    ['areasFilters', reportFilters?.stores, userStoreId],
    () =>
      getFilterAreas({
        stores:
          reportFilters?.stores?.length > 0 ? reportFilters?.stores : undefined,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const areasOptions = useMemo(() => {
    if (!AreasFilter) return [];

    return AreasFilter?.map(area => {
      return {
        label: area,
        value: area,
      };
    });
  }, [AreasFilter]);

  // Stores filter
  const {data: StoresFilter, isFetching: StoresFilterLoading} = useQuery(
    ['storesFilters', reportFilters?.regions, userStoreId],
    () =>
      getFilterStores({
        regions:
          reportFilters?.regions?.length > 0
            ? reportFilters?.regions
            : undefined,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const storesOptions = useMemo(() => {
    if (!StoresFilter) return [];

    return StoresFilter?.map(store => {
      return {
        label: `${store?.storeId}${
          !!store?.storeName ? ` - ${store?.storeName}` : ''
        }`,
        value: store?.uuid,
      };
    });
  }, [StoresFilter]);

  // Campaigns filter
  const {data: CampaignsFilter, isFetching: CampaignsFilterLoading} = useQuery(
    [
      'campaignsFilters',
      reportFilters?.startDate,
      reportFilters?.endDate,
      userStoreId,
    ],
    () =>
      getFilterCampaigns({
        startDate: reportFilters?.startDate?.utc().format('YYYY-MM-DD'),
        endDate: reportFilters?.endDate?.utc().format('YYYY-MM-DD'),
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const campaignsOptions = useMemo(() => {
    if (!CampaignsFilter) return [];

    return CampaignsFilter?.map(campaign => {
      return {
        label: campaign?.name,
        value: campaign?.code,
      };
    });
  }, [CampaignsFilter]);

  // Status filter
  const {data: StatusFilter, isFetching: StatusFilterLoading} = useQuery(
    ['statusFilters'],
    () => getFilterStatus(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const statusOptions = useMemo(() => {
    if (!StatusFilter) return [];

    return StatusFilter?.map(status => {
      return {
        label: t(`Campaign.card.${status}`),
        value: status,
      };
    });
  }, [StatusFilter, t]);

  // Call table info
  const {data: ReportsCampaigns, isFetching: ReportsCampaignsLoading} =
    useQuery(
      ['reportsCampaigns', reportFilters, userStoreId],
      () =>
        getReportsCampaigns({
          page: reportFilters?.page,
          sortBy: reportFilters?.sortBy,
          orderBy: reportFilters?.orderBy,
          startDate: reportFilters?.startDate?.utc()?.format('YYYY-MM-DD'),
          endDate: reportFilters?.endDate?.utc()?.format('YYYY-MM-DD'),
          codes:
            reportFilters?.codes?.length > 0 ? reportFilters?.codes : undefined,
          regions:
            reportFilters?.regions?.length > 0
              ? reportFilters?.regions
              : undefined,
          stores:
            reportFilters?.stores?.length > 0
              ? reportFilters?.stores
              : undefined,
          status:
            reportFilters?.status?.length > 0
              ? reportFilters?.status
              : undefined,
        }),
      {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      },
    );

  // Call table info
  const {data: ReportsHeader, isLoading: ReportsHeaderLoading} = useQuery(
    [
      'reportsHeader',
      reportFilters?.startDate,
      reportFilters?.endDate,
      reportFilters?.codes,
      reportFilters?.regions,
      reportFilters?.stores,
      reportFilters?.status,
      userStoreId,
    ],
    () =>
      getReportsHeader({
        startDate: reportFilters?.startDate?.utc()?.format('YYYY-MM-DD'),
        endDate: reportFilters?.endDate?.utc()?.format('YYYY-MM-DD'),
        codes:
          reportFilters?.codes?.length > 0 ? reportFilters?.codes : undefined,
        regions:
          reportFilters?.regions?.length > 0
            ? reportFilters?.regions
            : undefined,
        stores:
          reportFilters?.stores?.length > 0 ? reportFilters?.stores : undefined,
        status:
          reportFilters?.status?.length > 0 ? reportFilters?.status : undefined,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const handleDateChange = useCallback(
    (startDate: Dayjs, endDate: Dayjs) => {
      dispatch(ReportFiltersChangeDates(startDate, endDate));
    },
    [dispatch],
  );

  const handleChange = useCallback(
    (key: keyof ReportFilters, value: any) => {
      dispatch(ReportFiltersChangeField(key, value));
    },
    [dispatch],
  );

  //Sort Columns
  const handleColumnHeaderClick = useCallback(
    (params: GridColumnHeaderParams) => {
      const {field, colDef} = params;
      const {sortable} = colDef;

      if (sortable) {
        dispatch(
          ReportFiltersChangeField(
            'orderBy',
            reportFilters?.orderBy === 'ASC' ? 'DESC' : 'ASC',
          ),
        );
        dispatch(ReportFiltersChangeField('sortBy', field));
      }
    },
    [dispatch, reportFilters?.orderBy],
  );

  // Change Table Page
  const changePage = useCallback(
    (page: number) => {
      dispatch(ReportFiltersChangePage(page));
    },
    [dispatch],
  );

  //ChangeStore
  const handleChangeStoreSuccess = useCallback(async () => {
    await getAuth().then(value => {
      dispatch(ReportDetailsFiltersReset());
      dispatch(MembersReportFiltersReset());
      goToPath(AuthRoutes.CLIENTELING_DASHBOARD);
      showSuccessStoreChangeToast(
        t('Stores.toast.success'),
        `${value?.currentStore?.storeId}`,
        `${value?.currentStore?.storeName}`,
      );
    });
  }, [dispatch, getAuth, goToPath, showSuccessStoreChangeToast, t]);

  const {mutate: PatchSingleStore, isLoading: PatchSingleStoreLoading} =
    useMutation(patchStoreByStoreId, {
      onSuccess: async () => {
        await handleChangeStoreSuccess();
      },
      onError: () => {
        showErrorToast(t('Stores.toast.error'));
      },
    });

  const handleStoreSubmit = useCallback(
    (selectedStoreId: string) => {
      PatchSingleStore(selectedStoreId);
    },
    [PatchSingleStore],
  );

  // Table Rows
  const reportsRows = useMemo(
    () =>
      ReportsCampaigns?.data?.map(item => ({
        id: item.id,
        storeId: item.storeId,
        ZONE: item.zone,
        STORE: {
          id: item.sapStoreId,
          name: item.storeName,
          action: () => handleStoreSubmit(item.storeId),
        },
        FY_FW: {
          fy: item.fy,
          fw: item.fw,
        },
        NAME: {
          name: item.name,
          type: item.type,
          color: item.color,
        },
        STATUS: item.active,
        TASK_NUMBER: item.taskNumber,
        COMPLETED_TASK: `${item.completedTask} (${Math.round(
          item.completedTaskPerc,
        )}%)`,
        MOM: Math.round(item.mom),
        POSITIVE_FEEDBACK: `${item.positiveFeedback} (${Math.round(
          item.positiveFeedbackPerc,
        )}%)`,
        APPOINTMENT_BOOKED: `${item.appointmentBooked} (${Math.round(
          item.appointmentBookedPerc,
        )}%)`,
        APPOINTMENT_CHECKIN: `${item.appointmentCheckIn} (${Math.round(
          item.appointmentCheckInPerc,
        )}%)`,
      })) || [],
    [ReportsCampaigns?.data, handleStoreSubmit],
  );

  return (
    <Base
      title={t('AreaManager.Header.title')}
      noContainer={
        <Container
          maxWidth="xl"
          sx={{paddingTop: `${theme.spacing(2.4)} !important`}}>
          <Stack direction="column" gap={2}>
            <Stack sx={styles.filterContainer}>
              <Stack
                direction="row"
                alignItems="center"
                columnGap={0.8}
                flexWrap="wrap">
                <ChipDropdown
                  filterLabel={t('ReportsFilters.AREAS.multiple')}
                  allFilterLabel={t('ReportsFilters.AREAS.all')}
                  searchPlaceholder={t('ReportsFilters.AREAS.placeholder')}
                  selectedValues={reportFilters?.regions}
                  options={areasOptions}
                  onApply={regions => handleChange('regions', regions)}
                  disabled={AreasFilterLoading || !AreasFilter}
                />
                <ChipDropdown
                  filterLabel={t('ReportsFilters.STORES.multiple')}
                  allFilterLabel={t('ReportsFilters.STORES.all')}
                  searchPlaceholder={t('ReportsFilters.STORES.placeholder')}
                  selectedValues={reportFilters?.stores}
                  options={storesOptions}
                  onApply={stores => handleChange('stores', stores)}
                  disabled={StoresFilterLoading || !StoresFilter}
                />
                <ChipDropdown
                  filterLabel={t('ReportsFilters.STATUS.multiple')}
                  allFilterLabel={t('ReportsFilters.STATUS.all')}
                  searchPlaceholder={t('ReportsFilters.STATUS.placeholder')}
                  selectedValues={reportFilters?.status}
                  options={statusOptions}
                  onApply={status => handleChange('status', status)}
                  disabled={StatusFilterLoading || !StatusFilter}
                />
                <ChipDropdown
                  filterLabel={t('ReportsFilters.CAMPAIGNS.multiple')}
                  allFilterLabel={t('ReportsFilters.CAMPAIGNS.all')}
                  searchPlaceholder={t('ReportsFilters.CAMPAIGNS.placeholder')}
                  selectedValues={reportFilters?.codes}
                  options={campaignsOptions}
                  onApply={codes => handleChange('codes', codes)}
                  disabled={CampaignsFilterLoading || !CampaignsFilter}
                />
                <ChipDatepicker
                  startDate={reportFilters?.startDate}
                  endDate={reportFilters?.endDate}
                  defaultStartDate={reportFiltersInitialStartDate}
                  defaultEndDate={reportFiltersInitialEndDate}
                  onApply={handleDateChange}
                  minDate={dayjs().utc().subtract(60, 'days')}
                  maxDate={dayjs().utc()}
                />
              </Stack>
              <Link
                linkVariant="dark"
                textVariant="body"
                href="#"
                iconStart={<IconSVG icon="reset" size={16} />}
                handleOnClick={() => {
                  dispatch(ReportFiltersReset());
                }}
                disabled={!hasFilters}
                noDecoration>
                {t('PurchaseHistory.filters.reset')}
              </Link>
            </Stack>
            {ReportsHeaderLoading || !ReportsHeader ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height={80}
              />
            ) : (
              <HeadingCard
                translationPath="ReportHeader"
                items={ReportsHeader}
              />
            )}
          </Stack>
        </Container>
      }>
      {PatchSingleStoreLoading && <LoadingPage invisible={false} />}
      {!ReportsCampaignsLoading ? (
        <Table
          sortModel={[
            {
              field: reportFilters?.sortBy ?? '',
              sort: reportFilters?.orderBy?.toLocaleLowerCase() as any,
            },
          ]}
          sx={{
            '.MuiDataGrid-row': {
              cursor: 'pointer',
              opacity: 1,
              transition: 'all 0.2s linear',
              '&:hover': {
                opacity: 0.7,
              },
            },
          }}
          columnHeaderHeight={74}
          getRowId={row => row.id}
          checkbox={false}
          columns={reportsColumns}
          onRowClick={params => {
            params?.row?.STORE.action();
          }}
          rows={reportsRows}
          currentPage={reportFilters?.page}
          onColumnHeaderClick={handleColumnHeaderClick}
          totalPages={
            ReportsCampaigns?.metadataDTO
              ? ReportsCampaigns?.metadataDTO?.total_pages
              : 1
          }
          setPage={(e, page) => changePage(page)}
          loading={ReportsCampaignsLoading || !ReportsCampaigns}
        />
      ) : (
        <Skeleton
          height={400}
          animation="wave"
          variant="rounded"
          width="100%"
        />
      )}
    </Base>
  );
};

export default React.memo(AreaManagerHome);
